<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
          Historial de Pagos
        </p>
      </div>
    </v-banner>

    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="black--text">
              <v-icon left color="naranja">mdi-filter</v-icon>
              Filtros
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="fecha_desde"
                    v-model="fecha_desde"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filtros.date_start"
                        label="Fecha Inicio"
                        required
                        outlined
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="filtros.date_start"
                      @input="fecha_desde = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="fecha_hasta"
                    v-model="fecha_hasta"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filtros.date_end"
                        label="Fecha Final"
                        required
                        outlined
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="filtros.date_end"
                      @input="fecha_hasta = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-select
                    label="Tipo de compra"
                    :items="types"
                    item-text="tipo"
                    item-value="key"
                    required
                    outlined
                    hide-details
                    v-model="filtros.type"
                    @change="changeHeaders"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-btn x-large color="primary" @click="getHistory()" block>
                    <v-icon left>mdi-magnify</v-icon>
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-data-table
              v-if="searchInArray(actions, 'Ver')"
              :headers="data.headers"
              :items="data.data"
              :item-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Lista de Pagos
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <vue-json-to-csv
                            :json-data="csvData"
                            :labels="labelsData"
                            csv-title="Lista De Estudiantes"
                          >
                            <v-icon>mdi-file-excel</v-icon>
                            Descargar CSV
                          </vue-json-to-csv>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <template v-slot:[`item.response_json`]="{ item }">
                {{ item.response_json.length > 20 ? "En Linea" : "En Caja" }}
              </template>
              <template v-slot:[`item.monto`]="{ item }">
                $ {{ item.monto }}
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <template v-if="searchInArray(actions, 'Editar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :disabled="item.response_json.length > 20"
                        @click="
                          selectPay(item);
                          modalUpdate();
                        "
                        color="amber"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Eliminar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :disabled="item.tipo != 'libro'"
                        class="ml-5"
                        @click="
                          selectPay(item);
                          modalDelete();
                        "
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modals -->
    <UpdatePay ref="mdlUpdate" :pay="slcPay" :clean="cleanUpdate"></UpdatePay>
    <!-- Dialog Delete -->
    <v-dialog v-model="dialogDelete" max-width="300px">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <p class="mb-0 mt-4">
            ¿Está seguro de eliminar este elemento?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="closeDelete()">
            Cancelar
          </v-btn>
          <v-btn color="red darken-1" text @click="deletePayConfirm()">
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueJsonToCsv from "vue-json-to-csv";
import UpdatePay from "./UpdatePay";
export default {
  name: "Accounting",
  components: {
    UpdatePay,
    VueJsonToCsv,
  },
  data: () => ({
    data: {
      load: false,
      headers: [
        //comun
        { text: "Codigo", value: "code" },
        { text: "Nombres", value: "first_name" },
        { text: "Apellidos", value: "last_name" },
        { text: "Fecha y hora", value: "date_hour" },
        { text: "Autorización", value: "authorization_code" },
        { text: "Transacción", value: "transaction" },
        { text: "Pago", value: "response_json" },
        //libro
        { text: "Libro", value: "concepto" },
        //curso
        { text: "Curso", value: "concepto" },
        { text: "Codigo Curso", value: "code_course" },
        //comun
        { text: "Total Pagado", value: "subtotal" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      data: [],
    },
    search: "",
    slcPay: {},
    dialogDelete: false,
    fecha_desde: false,
    fecha_hasta: false,
    filtros: {
      date_start: null,
      date_end: null,
      type: "both",
    },
    types: [
      { tipo: "Ambos", key: "both" },
      { tipo: "Curso", key: "course" },
      { tipo: "Libro", key: "book" },
    ],
  }),
  mounted() {
    this.getCurrentActions("Historial Pagos");
  },
  methods: {
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    getHistory: function() {
      this.data.load = true;
      this.$http
        .post(this.api + "pay/student/all", this.toFormData(this.filtros))
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    selectPay(pay) {
      this.slcPay = pay;
    },

    modalUpdate() {
      this.$refs.mdlUpdate.changeModal();
    },

    modalDelete() {
      this.dialogDelete = true;
    },

    deletePayConfirm() {
      this.isLoading = true;
      this.$http
        .delete(this.api + "pay/student/delete/" + this.slcPay.id_detail_pay)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeDelete();
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
          this.closeDelete();
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.slcPay = {};
      });
    },

    cleanUpdate() {
      this.slcPay = {};
      this.getHistory();
    },

    changeHeaders: function() {
      switch (this.filtros.type) {
        case "course":
          this.data.headers = [
            //comun
            { text: "Codigo", value: "code" },
            { text: "Nombres", value: "first_name" },
            { text: "Apellidos", value: "last_name" },
            { text: "Fecha y hora", value: "date_hour" },
            { text: "Autorización", value: "authorization_code" },
            { text: "Transacción", value: "transaction" },
            { text: "Pago", value: "response_json" },
            //curso
            { text: "Curso", value: "concepto" },
            { text: "Codigo Curso", value: "code_course" },
            //comun
            { text: "Total Pagado", value: "subtotal" },
            { text: "Acciones", value: "actions", sortable: false },
          ];
          break;
        case "book":
          this.data.headers = [
            //comun
            { text: "Codigo", value: "code" },
            { text: "Nombres", value: "first_name" },
            { text: "Apellidos", value: "last_name" },
            { text: "Fecha y hora", value: "date_hour" },
            { text: "Autorización", value: "authorization_code" },
            { text: "Transacción", value: "transaction" },
            { text: "Pago", value: "response_json" },
            //libro
            { text: "Libro", value: "concepto" },
            //comun
            { text: "Total Pagado", value: "subtotal" },
            { text: "Acciones", value: "actions", sortable: false },
          ];
          break;
        case "both":
          this.data.headers = [
            //comun
            { text: "Codigo", value: "code" },
            { text: "Nombres", value: "first_name" },
            { text: "Apellidos", value: "last_name" },
            { text: "Fecha y hora", value: "date_hour" },
            { text: "Autorización", value: "authorization_code" },
            { text: "Transacción", value: "transaction" },
            { text: "Pago", value: "response_json" },
            //libro
            { text: "Libro", value: "concepto" },
            //curso
            { text: "Curso", value: "concepto" },
            { text: "Codigo Curso", value: "code_course" },
            //comun
            { text: "Total Pagado", value: "subtotal" },
            { text: "Acciones", value: "actions", sortable: false },
          ];
          break;

        default:
          break;
      }
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      actions: "getCurrentActions",
    }),
    labelsData() {
      let headers = {};
      let localHeaders = this.data.headers;
      if (localHeaders.length > 0) {
        localHeaders.forEach((header) => {
          headers[header.value] = { title: header.text };
        });
      }
      return headers;
    },
    csvData() {
      let data = [];
      //de esta manera le quito la reactividad a la variables
      let localDataJSON = JSON.stringify(this.data.data);
      let localdata = JSON.parse(localDataJSON);
      //transformando data
      if (localdata.length > 0) {
        localdata.forEach((item) => {
          //cambios por key
          for (var [key, value] of Object.entries(item)) {
            if (key == "monto") {
              item.monto = "$ " + item.monto;
            }
            if (key == "response_json") {
              item.response_json =
                item.response_json.length > 20 ? "En Linea" : "En Caja";
            }
          }
          if (item.code_course == undefined) {
            item.code_course = "N/A";
          }
          data.push(item);
        });
      }
      return data;
    },
  },
};
</script>

<style></style>
